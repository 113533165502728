import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import AboutUsSection from "../../components/AboutUsSection/AboutUsSection";
import SwiperSection from "../../components/SwiperSection/SwiperSection";
import ListSection from "../../components/ListSection/ListSection";
import ActivitySection from "../../components/ActivitySection/ActivitySection";
import BgImageSection from "../../components/BgImageSection/BgImageSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import { useEffect } from "react";

const MainPage = () => {
   useEffect(() => {
      const alreadyLoaded = localStorage.getItem("alreadyLoaded");
      if (!alreadyLoaded) {
         localStorage.setItem("alreadyLoaded", true);
         window.location.reload();
      }
   }, []);

   return (
      <>

         <HeroSection />
         <AboutUsSection />
         <SwiperSection />
         <ListSection />
         <ActivitySection />
         <BgImageSection />
         <PartnersSection />
      </>
   );
};

export default MainPage;
