import React from "react";
import styles from "./AboutUsSection.module.css";
import { useLanguage } from "../../contexts/languageContext";
const AboutUsSection = () => {
   const {
      section,
      content,
      left,
      right,
      leftTitle,
      leftSubtitle,
      leftBtn,
      rightTitle,
      rightSubtitle,
   } = styles;
   const { language } = useLanguage();
   return (
      <div className={section}>
         <div className="container">
            <div className={content}>
               <div
                  data-aos-duration="1000"
                  data-aos="fade-right"
                  className={left}
               >
                  <p className={leftTitle}>
                     {language !== "RU"
                        ? "We see our main goal in establishing and strengthening cooperation and business ties between Korean and Russian companies."
                        : "Нашей целью является установление и расширение торгово-деловых связей между корейскими и российскими компаниями"}
                  </p>
                  <p className={leftSubtitle}>
                     {language !== "RU"
                        ? "Not only do we actively cooperate with companies from central part of Russia, but we also work with the regions and countries of the customs union; help establishing business connections with enterprises located in the Gyeonggi province and facilitate the direct import of products from Korea."
                        : " Мы активно сотрудничаем не только с компаниями из Центральной части России, но и с регионами, помогаем налаживать деловые связи с предприятиями, расположенными в провинции Кёнгидо и способствуем прямому импорту продукции из Кореи."}
                  </p>
                  <a href="https://youtu.be/aHiW2py6dRY?si=Ao_6viQFcmpJYBek" className={leftBtn}>
                     {language === "RU" ? "Подробнее" : "More"}

                     <img src="https://i.ibb.co/Bcnt5W0/1.png" alt="#" />
                  </a>
               </div>
               <div
                  data-aos-duration="1000"
                  data-aos="fade-left"
                  className={right}
               >
                  <p className={rightTitle}>
                     {language === "RU"
                        ? "Кёнги Бизнес Центр (GBC Moscow) – некоммерческая организация при Правительстве провинции Кёнгидо, Республика Корея, созданная для поддержки и развития малого и среднего бизнеса."
                        : "Gyeonggi Business Center (GBC Moscow) is a non-profit organization under the Government of Gyeonggi Province, Republic of Korea, created to support and develop small and medium-sized businesses."}
                  </p>
                  <p className={rightSubtitle}>
                     {language === "RU"
                        ? "Представительство Кёнги Бизнес Центра в Москве было основано в 2006 году и уже более 17 лет занимается продвижением корейских товаров на территории России и в странах СНГ."
                        : "The representative office of the Gyeonggi Business Center in Moscow was founded in 2006 and has been promoting Korean goods in Russia and the CIS countries for more than 17 years."}
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AboutUsSection;
