import React from "react";
import styles from "./Footer.module.css";
import { useLanguage } from "../../contexts/languageContext";
import { useRef } from "react";
import { useState } from "react";
import emailjs from '@emailjs/browser';

const Footer = () => {
   const {
      section,
      cardContainer,
      form,
      logoContainer,
      inputContainer,
      card,
      cardTitle,
      cardDesc,
      descContainer,
      desc,
   } = styles;
   const { language } = useLanguage();
   const [value, setValue] = useState('');
   const formRef = useRef();
   const sendEmail = (e) => {
      e.preventDefault();
      if(value && value !== ' ') {
         emailjs.sendForm('service_cjiomus', 'template_oyehp7q', formRef.current , 'LrrV5-VP9r8gzgz_T')
         .then((result) => {
             console.log(result.text);
             alert(language === 'RU' ?  'Спасибо за заявку' : 'Thanks for your application')
   
         }, (error) => {
             console.log(error.text);
             alert(language === 'RU' ?  'Не получилось отправить заявку' : 'Failed to submit application')
   
         });
      setValue('')

      }
    };
   return (
      <footer className={section}>
         <div className="container">
            <div className={cardContainer}>
               <form
               ref={formRef}
                  onSubmit={sendEmail}
                  className={form}
               >
                  <div className={logoContainer}>
                     <img src="https://i.ibb.co/jyJn7SW/image.png" alt="GBC" />
                     <p>Moscow</p>
                  </div>
                  <p className={desc}>
                     {language === "RU"
                        ? "Воплотите свою идею в жизнь с помощью GBC"
                        : "Make your dream come true with GBC Moscow"}
                  </p>
                  <div className={inputContainer}>
                     <input
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="text"
                        id="message"
                        name="message"
                        placeholder={
                           language === "RU" ? "Ваш e-mail" : "Your e-mail"
                        }
                     />
                     <button type="submit">
                        {language === "RU" ? "Отправить" : "Send"}
                        <img src="https://i.ibb.co/nnxRn9T/SVG-2.png" alt="#" />
                     </button>
                  </div>
               </form>
               <div className={card}>
                  <p className={cardTitle}>
                     {" "}
                     {language === "RU" ? "Контакты" : "Contacts"}
                  </p>
                  <div className={descContainer}>
                     <p className={cardDesc}>+7 499 426 20 07</p>
                     <p className={cardDesc}>gbc1@gbcmos.com</p>
                  </div>
               </div>
               <div className={card}>
                  <p className={cardTitle}>
                     {" "}
                     {language === "RU" ? "Адрес" : "Address"}
                  </p>
                  <div className={descContainer}>
                     <p className={cardDesc}>
                        {" "}
                        {language === "RU"
                           ? `Профсоюзная ул., 65к1, БЦ Лотте, Москва,
                              Россия`
                           : "Profsoyuznaya st., 65k1, Lotte Business center"}
                     </p>
                  </div>
               </div>
               <div className={card}>
                  <p className={cardTitle}> </p>
                  <div className={descContainer}>
                     <p className={cardDesc}></p>
                     <p className={cardDesc}></p>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
