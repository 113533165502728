import React, { useEffect, useState } from "react";
import styles from "./SwiperSection.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { useLanguage } from "../../contexts/languageContext";
import "swiper/css/navigation";
import { useLocation } from "react-router-dom";
const SwiperSection = () => {
   const {
      section,
      slide,
      slideContent,
      slideTitle,
      slideSubtitle,
      slideList,
      extraTitle,
      extraSubtitle,
      btn,
      slideContentActive,
      slideImg,
      containerSwiper,
      sliderImgContainer,
      descContainer,
      secondDesc,
   } = styles;
   const { language } = useLanguage();
   const [advantages, setAdvantages] = useState([
      "1. Прозрачное ведение бизнеса.",

      "2. Компетентная консультация",

      "3. Ответственный менеджмент.",

      "4. Ориентированность на запрос потребителя.",

      "5. Налаживание прямых поставок без посредников.",
   ]);
   const params = useLocation();
   useEffect(() => {
      if (language === "EN") {
         setAdvantages([
            "1. Transparent business practices.",
            "2. Competent consultation.",
            "3. Responsible management.",
            "4. Focus on consumer requests.",
            "5. Establishing direct deliveries without intermediaries.",
         ]);
      }
      if(language === 'RU') {
         setAdvantages([
            "1. Прозрачное ведение бизнеса.",

      "2. Компетентная консультация",

      "3. Ответственный менеджмент.",

      "4. Ориентированность на запрос потребителя.",

      "5. Налаживание прямых поставок без посредников.",
         ])
      }
   }, [language]); 
   return (
      <div className={section}>
         <div className={`container ${containerSwiper}`}>
            <Swiper
               data-aos-duration={!params.hash ? "1000" : null}
               data-aos={!params.hash ? "fade-right" : null}
               spaceBetween={0}
               navigation={{
                  nextEl: ".image-swiper-button-next-small",
                  prevEl: ".image-swiper-button-prev-small",
               }}
               modules={[Navigation]}
               breakpoints={{
                  1200: {
                     slidesPerView: 2.1,
                  },
                  992: {
                     slidesPerView: 1.5,
                  },
                  768: {
                     slidesPerView: 1.1,
                  },
                  576: {
                     slidesPerView: 1.2,
                  },
               }}
            >
               {[
                  {
                     img: "https://i.ibb.co/XbQcLhq/business-home-slider-img4-jpg.png",
                  },
         
                  {
                     img: "https://img-host.ru/69B0g.png",
                  },
                  {
                     img: "https://i.ibb.co/1rjbqD6/1672820906382.jpg",
                  },
                  {
                     img: "https://i.ibb.co/3hn3zJj/46730ca0-fc40-439c-b456-cc7da9da08d0.jpg",
                  },
                  {
                     img: "https://i.ibb.co/PQb1L9V/652cb589-a887-49f9-8aea-abb67499b41c.jpg",
                  },
                  {
                     img: "https://i.ibb.co/R3FGdgC/67883fb4-50c5-4cab-b8bb-6ee3e98bb84c.jpg",
                  },
                  {
                     img: "https://i.ibb.co/L6529S1/6e99f914-58eb-433c-999d-e98b0b466504.jpg",
                  },
                  {
                     img: "https://i.ibb.co/Cn6YMmB/7adad4bd-a307-4c5a-858f-2da64ae561eb.jpg",
                  },
                  {
                     img: "https://i.ibb.co/6JDdYtV/2f48f41e-886f-4dbe-90fe-cdc31ef658f2.jpg",
                  },
                  {
                     img: "https://i.ibb.co/1QnySwQ/c8694faa-a95a-41c4-abb5-1259eb2568c5.jpg",
                  },
               ].map((el, index) => {
                  return (
                     <SwiperSlide className={slide} key={index}>
                        <div className={sliderImgContainer}>
                           <img className={slideImg} src={el.img} alt="#" />

                           <button
                              type="button"
                              className="image-swiper-button-prev-small"
                           ></button>
                           <button
                              type="button"
                              className="image-swiper-button-next-small"
                           ></button>
                        </div>
                     </SwiperSlide>
                  );
               })}
               <div
                  data-aos-duration={!params.hash ? "1000" : null}
                  data-aos={!params.hash ? "fade-right" : null}
                  className={descContainer}
               >
                  <div className={`${slideContent} ${slideContentActive}`}>
                     <p className={slideTitle}>
                        {language === "RU"
                           ? `Преимущества сотрудничества с GBC Moscow`
                           : "Benefits of cooperation with GBC Moscow"}
                     </p>
                     <p className={slideSubtitle}></p>
                     <ul className={slideList}>
                        {advantages.map((el, index) => (
                           <li key={index}>
                              <span>{el}</span>
                           </li>
                        ))}
                     </ul>
                     <p className={extraTitle}></p>
                     <p className={extraSubtitle}>
                        {language === "RU"
                           ? "GBC Moscow содействует развитию международной коммерции и инвестиций между производителями провинции Кенгидо и ее торговыми партнерами в России и странах Торгового таможенного союза."
                           : "GBC Moscow promotes the development of international commerce and investment between Gyeonggi manufacturers and its trading partners in Russia and the countries of the Trade Customs Union."}
                     </p>
                     {/* <button className={btn}>
                        Подробнее{" "}
                        <img
                           src="https://imageup.ru/img217/4607810/group-3.png"
                           alt="#"
                        />
                     </button> */}
                  </div>
                  <div className={secondDesc}>
                     <p className={extraTitle}>
                        {
                           language === 'RU' ? '  Наша компания полностью финансируется Правительством Южной Кореи, поэтому все услуги для российской стороны мы предоставляем на бесплатной основе.'   : 'Our company is fully funded by the Government of South Korea, so we provide all services for the Russian side free of charge.'
                        }
                      
                     </p>
                     <p className={extraSubtitle}>
                        {
                           language === "EN" ? 'The GBC Moscow team will provide you with comprehensive support in import process and will help you find a reliable business partner' : 'Команда GBC Moscow окажет Вам всестороннюю поддержку в процессе импорта и поможет найти для Вас надежного бизнес партнера.'
                        }
                        
                     </p>
                  </div>
               </div>
            </Swiper>
         </div>
      </div>
   );
};
export default SwiperSection;
