import React from "react";
import styles from "./BgImageSection.module.css";
import { useLanguage } from "../../contexts/languageContext";
import { useLocation, useNavigate } from "react-router-dom";
const BgImageSection = () => {
   const { section, bg, cover, content, btn } = styles;
   const { language } = useLanguage();
   const navigate = useNavigate()
   const params = useLocation()
   return (
      <div className={section}>
         <img
            className={bg}
            src="https://i.ibb.co/GHFTxw0/business-bg-parallax-jpg-3.png"
            alt="#"
         />
         <div className={cover}></div>
         <div        data-aos-duration={!params.hash ? '1000' : null}
                  data-aos={!params.hash ? 'fade-left' : null} className={content}>
            <p>
               {language === "RU" ? (
                  <>
                     Долгосрочное планирование для <br /> неограниченного успеха
                  </>
               ) : (
                  <>Long-term planning for <br/> unlimited success</>
               )}
            </p>
            <button onClick={() => navigate('/aboutUs')} className={btn}>
            {language === "RU" ? "Подробнее" : "More"}
               <img
                  src="https://imageup.ru/img217/4607810/group-3.png"
                  alt="#"
               />
            </button>
         </div>
      </div>
   );
};

export default BgImageSection;
