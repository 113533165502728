import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./contexts/languageContext";
import { ModalWindowProvider } from "./contexts/modalWindowContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <ModalWindowProvider>
      <LanguageProvider>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </LanguageProvider>
   </ModalWindowProvider>
);
