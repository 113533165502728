import React from "react";
import styles from "./ContactsPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { YMaps, Map } from "@pbe/react-yandex-maps";
import { useLanguage } from "../../contexts/languageContext";
import { useModal } from "../../contexts/modalWindowContext";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import { useEffect } from "react";
const ContactsPage = () => {
   const {
     
      contentContainer,
      desc,
      title,
      socialLinkContainer,
      textContainer,
      map,
      descContainer,
   } = styles;
   const { language } = useLanguage();
   const { modal, setModal } = useModal();
   useEffect(() => {
      if (modal) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
   }, [modal]);
   return (
      <>
         <ModalWindow isOpen={modal} setIsOpen={setModal} />
         <Header anotherPage={true} />
         <main className="main">
            <div className="container">
               <div className={contentContainer}>
                  <div className={textContainer}>
                     <p className={title}>
                        {language === "RU"
                           ? "Свяжитесь с нами:"
                           : "Contact us:"}
                     </p>
                     <div className={descContainer}>
                        <p className={desc}>
                           {language === "RU"
                              ? ` Адрес: Профсоюзная ул., 65к1, БЦ Лотте, Москва,
                              Россия`
                              : "Address: Profsoyuznaya st., 65k1, Lotte Business center"}
                        </p>
                        <p className={desc}>
                           {" "}
                           {language === "RU"
                              ? `Тел. номер:+7 499 426 20 07`
                              : "Tel. number: +7 499 426 20 07"}
                        </p>
                        <p className={desc}>
                           {" "}
                           {language === "RU"
                              ? "Эл. почта: gbc1@gbcmos.com"
                              : "E-mail : gbc1@gbcmos.com"}{" "}
                        </p>
                     </div>

                     {/* <div className={socialLinkContainer}>
                        <p className={desc}>
                           {language === "RU" ? "Соц сети" : "Social links"}
                        </p>
                     </div> */}
                  </div>

                  <div className={map}>
                     <YMaps>
                        <Map
                           width={"100%"}
                           height={"100%"}
                           defaultState={{
                              center: [55.653246, 37.538339],
                              zoom: 17,
                           }}
                        />
                     </YMaps>
                  </div>
               </div>
            </div>
         </main>
         <Footer />
      </>
   );
};
export default ContactsPage;
