import { createContext, useContext, useState } from "react";
const LanguageContext = createContext();
export const useLanguage = () => {
   return useContext(LanguageContext);
};
export const LanguageProvider = ({ children }) => {
   const [language, setLanguage] = useState('RU');
   
   return (
      <LanguageContext.Provider
         value={{
            language: language,
            setLanguage: setLanguage,
         }}
      >
         {children}
      </LanguageContext.Provider>
   );
};