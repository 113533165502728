import React, { useState } from "react";
import styles from "./AboutUsVideoSection.module.css";
import videoSrc from "../../assets/video/seoul.mp4";
import { useRef } from "react";
import { useLanguage } from "../../contexts/languageContext";
const AboutUsVideoSection = () => {
   const {
      section,
      video,
      contentContainer,
      container,
      textContainer,
      title,
      subtitle,
      playVideoBtn,
      activeBtn,
      unactiveBtn,
      cover,
   } = styles;
   const vidRef = useRef(null);
   const [play, setPlay] = useState(true);
   const { language } = useLanguage();
   const handlePlay = () => {
      vidRef.current.play();
      setPlay(true);
   };
   const handlePause = () => {
      vidRef.current.pause();
      setPlay(false);
   };
   return (
      <div className={section}>
         <video
            className={video}
            src={videoSrc}
            autoPlay
            loop
            ref={vidRef}
            muted
            playsInline
         ></video>
         <div className={cover}></div>
         <div className={contentContainer}>
            <div className={`container ${container}`}>
               <div className={textContainer}>
                  <p
                     data-aos-duration="1000"
                     data-aos="fade-right"
                     className={title}
                  >
                     {language === "RU" ? "О GBC Moscow" : "About GBC Moscow"}
                  </p>
                  <p
                     data-aos-duration="1000"
                     data-aos="fade-right"
                     className={subtitle}
                  >
                     {language === "RU"
                        ? `   Московское представительство Кёнги Бизнес Центр (GBC
                           Moscow) – некоммерческая организация при Правительстве
                           провинции Кёнгидо, Республика Корея, созданная для
                           поддержки и развития малого и среднего бизнеса данного
                           региона. Нашей целью является установление и расширение
                           торгово-деловых связей между корейскими и российскими
                           компаниями. Представительство Кёнги Бизнес Центра в Москве
                           было основано в 2006 году и уже более 17 лет мы занимается
                           продвижением корейских товаров на территории России и в
                           странах СНГ.`
                        : `Moscow representative office of Gyeonggi Business Center (GBC Moscow) is a non-profit organization under the Government of Gyeonggi Province, Republic of Korea, created to support and develop small and medium-sized businesses in the region.
                        Our goal is to establish and expand trade and business ties between Korean and Russian companies.
                        The representative office of the Gyeonggi Business Center in Moscow was founded in 2006 and for more than 17 years we have been promoting Korean goods in Russia and the CIS countries.
                       `}
                  </p>
               </div>
               <div className={playVideoBtn}>
                  <img
                     className={!play ? activeBtn : unactiveBtn}
                     onClick={() => handlePlay()}
                     src="https://i.ibb.co/hynLPd3/div-elementor-icon.png"
                     alt="#"
                  />
                  <img
                     src="https://i.ibb.co/s1BKd7y/image.png"
                     className={play ? activeBtn : unactiveBtn}
                     onClick={() => handlePause()}
                     alt="#"
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default AboutUsVideoSection;
