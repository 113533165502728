import React, { useEffect } from "react";
import styles from "./HeroSection.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import { useLanguage } from "../../contexts/languageContext";
import ModalWindow from "../ModalWindow/ModalWindow";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { useLocation } from "react-router-dom";

const HeroSection = () => {
   const {
      section,
      slide,
      content,
      changeLang,
      changeLangList,
      changeLangListActive,
      mainBg,
      cover,
      container,
      right,
      rightTitle,
      rightSubtitle,
   } = styles;
   const { language, setLanguage } = useLanguage();
const params = useLocation()
   return (
    
      <div className={section}>

         <div className={`container ${container}`}>
            <div>
               <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  speed={800}
                  autoplay={{ delay: 1000 }}
                  loop={true}
                  fadeEffect={{
                     crossFade: true,
                  }}
                  effect="fade"
                  modules={[EffectFade, Autoplay]}
               >
                  <SwiperSlide
                    data-aos-duration={!params.hash ? '1000' : null}
                    data-aos={!params.hash ? 'fade-left' : null}
                     className={slide}
                  >
                     <p>
                        {language === "RU" ? (
                           <>
                              {" "}
                              GBC Moscow - Ваш проводник <br /> в мир корейского
                              бизнеса
                           </>
                        ) : (
                           <>
                              GBC Moscow - Your guide to <br /> the world of
                              Korean business
                           </>
                        )}
                     </p>
                  </SwiperSlide>
               </Swiper>
               <div
                  onClick={(e) => {
                     e.currentTarget.children[2].classList.toggle(
                        changeLangListActive
                     );
                  }}
                  className={changeLang}
               >
                  <img
                     src="https://i.ibb.co/8YVk9NJ/rs-group-wrap-rs-group-rs-layer-wrap-rs-layer-business-rev-globe-png.png"
                     alt="#"
                  />
                  <p>Language</p>

                  <ul
                     onClick={(e) => {
                        setLanguage(e.target.dataset.lang);
                     }}
                     className={changeLangList}
                  >
                     <li data-lang="RU">RU</li>
                     <li data-lang="EN">EN</li>
                  </ul>
               </div>
            </div>

            <div
                data-aos-duration={!params.hash ? '1000' : null}
                data-aos={!params.hash ? 'fade-right' : null}
               className={content}
            >
               <img src="https://i.ibb.co/QHKrQfS/Mask-group-3.png" alt="#" />
            </div>
            <div
                 data-aos-duration={!params.hash ? '1000' : null}
                 data-aos={!params.hash ? 'fade-right' : null}
               className={right}
            >
               <p className={rightTitle}>
                  {language === "RU"
                     ? "Кёнги Бизнес Центр (GBC Moscow) – некоммерческая организация при Правительстве провинции Кёнгидо, Республика Корея, созданная для поддержки и развития малого и среднего бизнеса."
                     : "Gyeonggi Business Center (GBC Moscow) is a non-profit organization under the Government of Gyeonggi Province, Republic of Korea, created to support and develop small and medium-sized businesses."}
               </p>
               <p className={rightSubtitle}>
                  {language === "RU"
                     ? "Представительство Кёнги Бизнес Центра в Москве было основано в 2006 году и уже более 17 лет занимается продвижением корейских товаров на территории России и в странах СНГ."
                     : "The representative office of the Gyeonggi Business Center in Moscow was founded in 2006 and has been promoting Korean goods in Russia and the CIS countries for more than 17 years."}
               </p>
            </div>

         </div>
         <img
            src="https://i.ibb.co/wznNpK7/Rectangle-3-1.png"
            className={mainBg}
            alt="#"
         />
         <div className={cover}></div>
      </div>
   );
};

export default HeroSection;
