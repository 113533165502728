import React from "react";
import styles from "./SideBar.module.css";
import { Link as ScrollLink } from "react-scroll";
import { Link as NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const SideBar = ({ isOpen, setIsOpen, anotherPage }) => {
   const { sidebar, menu, closeBtn, sidebarActive } = styles;
   return (
      <div className={isOpen ? `${sidebar} ${sidebarActive}` : sidebar}>
         <div className={menu}>
            {anotherPage ? (
               <HashLink
                 
                  smooth
                  to="/#activity"
                  onClick={(e) => setIsOpen(false)}
               >
                  Деятельность
               </HashLink>
            ) : (
               <ScrollLink
              
                  to="activity"
                  className="testLink"
                  smooth={true}
                  onClick={(e) => setIsOpen(false)}
               >
                  Деятельность
               </ScrollLink>
            )}
            {anotherPage ? (
               <HashLink
                  
                  smooth
                  to="/#partners"
                  onClick={(e) => setIsOpen(false)}
               >
                  Наши партнеры
               </HashLink>
            ) : (
               <ScrollLink
                 
                  to={"partners"}
                  smooth={true}
                  onClick={(e) => setIsOpen(false)}
               >
                  Наши партнеры
               </ScrollLink>
            )}

            <NavLink to={"/aboutUs"} onClick={(e) => setIsOpen(false)}>
               О нас
            </NavLink>
            <NavLink to={"/contacts"} onClick={(e) => setIsOpen(false)}>
               Контакты
            </NavLink>
         </div>
         <img
            className={closeBtn}
            onClick={() => setIsOpen(false)}
            src="https://i.ibb.co/tQXgsSg/image.png"
            alt="#"
         />
      </div>
   );
};

export default SideBar;
