import React, { useEffect } from "react";
import styles from "./AboutUsPage.module.css";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import AboutUsVideoSection from "../../../components/AboutUsVideoSection/AboutUsVideoSection";
import { useLanguage } from "../../../contexts/languageContext";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { useModal } from "../../../contexts/modalWindowContext";
const AboutUsPage = () => {
   const { videoContainer, textContainer, title, cardContainer, card } = styles;
   const { language } = useLanguage();
   const {modal ,setModal} = useModal()
   useEffect(() => {
      if(modal) {
         document.body.style.overflow = 'hidden'

      }else {
         document.body.style.overflow = 'auto'
      }
   } , [modal])
   return (
      <>
      <ModalWindow isOpen={modal} setIsOpen={setModal} />
         <Header anotherPage={true} isAbsolute={true} isWhiteHeader={true} />
         <main className={"main"}>
            <div className={videoContainer}>
               <AboutUsVideoSection />
            </div>
            <div className={textContainer}>
               <div className="container">
                  <p
                     data-aos-duration="1000"
                     data-aos="fade-right"
                     className={title}
                  >
                     {language === "RU"
                        ? `Мы активно сотрудничаем не только с компаниями из
                        Центральной части России, но и с регионами, помогаем
                        налаживать деловые связи с предприятиями, расположенными в
                        провинции Кёнгидо и способствуем прямому импорту продукции
                        из Кореи.`
                        : "We actively cooperate not only with companies from the Central part of Russia, but also with the regions, we help establish business connections with enterprises located in the Gyeonggi province and facilitate the direct import of products from Korea."}
                  </p>
                  <div className={cardContainer}>
                     <div
                        data-aos-duration="1000"
                        data-aos="fade-right"
                        className={card}
                     >
                        <img
                           src="https://i.ibb.co/dJ4FQPy/image-1.png"
                           alt="#"
                        />
                        <p>
                           {language === "RU"
                              ? `Кёнгидо – крупнейший промышленный регион, в котором
                              производится более 25% всего ВВП, производимого в
                              Корее.`
                              : `Gyeonggi is the largest industrial region which is producing more than 25% of Korea's total GDP.`}
                        </p>
                     </div>
                     <div data-="1500" data-aos="fade-right" className={card}>
                        <img
                           src="https://i.ibb.co/98W801C/image-2.png"
                           alt="#"
                        />
                        <p>
                           {language === "RU"
                              ? ` GBSA (Gyeonggi-do Business & Science Accelerator) –
                              комплексная поддержка малого и среднего бизнеса,
                              включая стартапы, инвестиции, экспорт, а также
                              научные и технологические исследования и разработки.`
                              : `GBSA (Gyeonggi-do Business & Science Accelerator) - comprehensive support for small and medium-sized businesses, including start-ups, investments, exports, and scientific and technological research and development.`}
                        </p>
                     </div>
                     <div
                        data-aos-duration="2000"
                        data-aos="fade-right"
                        className={card}
                     >
                        <img
                           src="https://i.ibb.co/LSVt2Ls/image-3.png"
                           alt="#"
                        />
                        <p>
                           {language === "RU"
                              ? `Республика Корея на протяжении многих лет входит в
                              десятку крупнейших торговых партнёров России.`
                              : `The Republic of Korea has been one of Russia's ten largest trading partners for many years.`}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </main>
         <Footer />
      </>
   );
};

export default AboutUsPage;
