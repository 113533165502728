import React from "react";
import styles from "./ListSection.module.css";
import { useLanguage } from "../../contexts/languageContext";
import { useLocation } from "react-router-dom";
const ListSection = () => {
   const { section, card, title, subtitle, cardContainer } = styles;
   const { language } = useLanguage();
   const params = useLocation()
   return (
      <div className={section}>
         <div className="container">
            <div className={cardContainer}>
               <div
                  data-aos-duration={!params.hash ? '1000' : null}
                  data-aos={!params.hash ? 'fade-right' : null}
                  className={card}
               >
                  <img src="https://i.ibb.co/Htkz8Y1/1.png" alt="#" />
                  <p className={title}>  38 000  </p>
                  <p className={subtitle}>   {language === "RU" ? " корейских производителей" : "korean manufacturers"}</p>
               </div>
               <div
                   data-aos-duration={!params.hash ? '1000' : null}
                   data-aos={!params.hash ? 'fade-right' : null}
                  className={card}
               >
                  <img src="https://i.ibb.co/Htkz8Y1/1.png" alt="#" />
                  <p className={title}>
                     {" "}
                     {language === "RU" ? "более 25%" : "More than 25%"}{" "}
                  </p>
                  <p className={subtitle}>
                     {language === "RU"
                        ? "от всего ВВП Кореи"
                        : "of Korea's total GDP"}
                  </p>
               </div>
               <div
                       data-aos-duration={!params.hash ? '1000' : null}
                       data-aos={!params.hash ? 'fade-right' : null}
                  className={card}
               >
                  <img src="https://i.ibb.co/Htkz8Y1/1.png" alt="#" />
                  <p className={title}>
                     {language === "RU" ? "22 офиса" : "22 offices"}
                  </p>
                  <p className={subtitle}>
                     {language === "RU"
                        ? "в 16 странах мира"
                        : "in 16 countries"}
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ListSection;
