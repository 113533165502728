import { createContext, useContext, useState } from "react";
const ModalWindowContext = createContext();
export const useModal = () => {
   return useContext(ModalWindowContext);
};
export const ModalWindowProvider = ({ children }) => {
   const [modal, setModal] = useState(false);
   
   return (
      <ModalWindowContext.Provider
         value={{
            modal: modal,
            setModal: setModal,
         }}
      >
         {children}
      </ModalWindowContext.Provider>
   );
};