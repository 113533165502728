import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout/MainLayout";
import MainPage from "./pages/MainPage/MainPage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AboutUsPage from "./pages/MainPage/AboutUsPage/AboutUsPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
function App() {
   useEffect(() => {
      AOS.init({
         once: true,
      });
   }, []);
   return (
      <Routes>
         <Route path="/" element={<MainLayout />}>
            <Route index element={<MainPage />} />
         </Route>
         <Route path="/aboutUs" element={<AboutUsPage />} />
         <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
   );
}

export default App;
