import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import { useModal } from "../../contexts/modalWindowContext";
const MainLayout = () => {
   const {modal , setModal} = useModal()
   useEffect(() => {
      if(modal) {
         document.body.style.overflow = 'hidden'

      }else {
         document.body.style.overflow = 'auto'
      }
   } , [modal])
   return (
      <>
         <ModalWindow isOpen={modal} setIsOpen={setModal} />
   
         <Header isWhiteHeader={true}  isAbsolute={true}/>
         <main className="main">
            <Outlet />
         </main>
<Footer/>

      </>
   );
};

export default MainLayout;
