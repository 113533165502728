import React, { useState } from "react";
import styles from "./Activity.module.css";
import { useLanguage } from "../../contexts/languageContext";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const ActivitySection = () => {
   const {
      section,
      title,
      cardContainer,
      card,
      cardContent,
      cardTitle,
      subtitle,
      cardText,
      cardHeader,
      number,
      headerTitle,
      cardActive,
      mainImg,
      mobileVersion,
      arrow,
      mobileContainer,
      mobileContent,
      mobileNumberContainer,
      btn,
      mobileTextContainer,
      numberMobile,
      arrowMobile,
      mobileMainImg,
      titleMobileActive,
      textMobileActive,
      mobileImgContainer,
      mobileImgActive,
      numberMobileActive,
      container,
      br,
      cardTitleMobile,
      subtitleMobile
   } = styles;
   const [id, setId] = useState(1);
   const sectionRef = useRef();
   const { language } = useLanguage();
   const params = useLocation()
   
   return (
      <div ref={sectionRef} id="activity" className={section}>
         <div className={`container ${container}`}>
            <p   data-aos-duration={!params.hash ? '1000' : null}
               data-aos={!params.hash ? 'fade-right' : null} className={title}>
               {language === "RU" ? "Деятельность" : "What we do"}
            </p>
            <div
         
               data-aos-duration={!params.hash ? '1000' : null}
               data-aos={!params.hash ? 'fade-left' : null}
               className={cardContainer}
            >
               <div
                  onMouseEnter={(e) => {
                     e.currentTarget.classList.add(cardActive);
                  }}
                  onMouseLeave={(e) => {
                     e.currentTarget.classList.remove(cardActive);
                  }}
                  className={`${card} ${cardActive}`}
               >
                  <div className={cardContent}>
                     <div className={cardText}>
                        <p className={cardTitle}>
                           {language === "RU" ? (
                              <>
                                 Информационная и <br /> коммуникационная <br />
                                 поддержка
                              </>
                           ) : (
                              <>
                                 Information and <br /> communication support
                              </>
                           )}
                        </p>
                        <p className={subtitle}>
                           {language === "RU" ? (
                              <>
                                 Организация деловой переписки и <br /> прямых
                                 переговоров с участием <br /> российских
                                 компаний и корейских <br /> поставщиков
                                 <br />
                                 <br />
                                 Поддержка в продвижении корейской <br />{" "}
                                 продукции на начальном этапе: <br />{" "}
                                 маркетинговые исследования рынка, <br />
                                 поиск потенциальных покупателей, PR- <br />{" "}
                                 агентств; сопровождение экспортных <br />{" "}
                                 контрактов
                                 <br />
                                 <br />
                                 Помощь в адаптации промо-материалов <br /> на
                                 русский язык
                              </>
                           ) : (
                              <>
                                 Organization of business correspondence <br />{" "}
                                 and direct negotiations between <br /> Russian
                                 companies and Korean suppliers; <br /> <br />
                                 Support in promoting Korean products at the
                                 <br />
                                 initial stage: market research,
                                 <br /> search for potential buyers <br /> and
                                 PR agencies; support <br /> of export contracts
                                 Assistance in <br /> adapting promotional
                                 materials into Russian.
                              </>
                           )}
                        </p>
                
                     </div>
                     <img
                        className={mainImg}
                        src="https://i.ibb.co/zZCdL1G/202613da57830d5dcf2d89c8a2432989.jpg"
                        alt="#"
                     />
                  </div>
                  <div className={cardHeader}>
                     <p className={number}>01</p>
                     <p className={headerTitle}>
                        {" "}
                        {language === "RU"
                           ? "Поддержка экспорта"
                           : "Export support"}
                     </p>
                     <img
                        className={arrow}
                        src="https://i.ibb.co/MRW3g8M/SVG.png"
                        alt="#"
                     />
                  </div>
               </div>
               <div
                  onMouseEnter={(e) => {
                     e.currentTarget.classList.add(cardActive);
                     e.currentTarget.parentElement.children[0].classList.remove(
                        cardActive
                     );
                  }}
                  onMouseLeave={(e) => {
                     e.currentTarget.classList.remove(cardActive);
                  }}
                  className={card}
               >
                  <div className={cardContent}>
                     <div className={cardText}>
                        <p className={cardTitle}>
                           {language === "RU" ? (
                              <>
                                 {" "}
                                 Организация <br /> переговоров
                              </>
                           ) : (
                              <>
                                 Organization <br />
                                 of negotiations
                              </>
                           )}
                        </p>
                        <p className={subtitle}>
                           {language === "RU" ? (
                              <>
                                 Организация онлайн/оффлайн переговоров <br />{" "}
                                 между потенциальными партнерами с <br />{" "}
                                 предоставлением услуг переводчика <br /> <br />
                                 Организация торговых миссий на <br />{" "}
                                 территории России и в странах <br />{" "}
                                 Таможенного союза
                              </>
                           ) : (
                              <>
                                 Organization of online/offline negotiations{" "}
                                 <br />
                                 between potential partners with <br />{" "}
                                 translation support provided
                                 <br />
                                 <br />
                                 Organization of trade missions <br /> on the
                                 territory of Russia <br /> and in the countries
                                 of the Customs Union
                              </>
                           )}
                        </p>
                  
                     </div>
                     <img
                        className={mainImg}
                        src="https://i.ibb.co/yWKLTRg/49468b6abfc185ed742232f811f31f5d.jpg"
                        alt="#"
                     />
                  </div>
                  <div className={cardHeader}>
                     <p className={number}>02</p>
                     <p className={headerTitle}>
                        {" "}
                        {language === "RU" ? (
                           <> Организация переговоров</>
                        ) : (
                           <>Organization of negotiations</>
                        )}
                     </p>
                     <img
                        className={arrow}
                        src="https://i.ibb.co/MRW3g8M/SVG.png"
                        alt="#"
                     />
                  </div>
               </div>{" "}
               <div
                  onMouseEnter={(e) => {
                     e.currentTarget.classList.add(cardActive);
                     e.currentTarget.parentElement.children[0].classList.remove(
                        cardActive
                     );
                  }}
                  onMouseLeave={(e) => {
                     e.currentTarget.classList.remove(cardActive);
                  }}
                  className={card}
               >
                  <div className={cardContent}>
                     <div className={cardText}>
                        <p className={cardTitle}>
                           {language === "RU" ? (
                              <>
                                 Организация <br /> деловых поездок
                              </>
                           ) : (
                              <>
                                 Organization of <br /> business trips
                              </>
                           )}
                        </p>
                        <p className={subtitle}>
                           {language === "RU" ? (
                              <>
                                 Сопровождение и организация деловых <br />{" "}
                                 поездок корейских производителей <br /> малого
                                 и среднего бизнеса на территории <br /> России
                                 и в странах Таможенного союза.
                                 <br />
                                 <br />
                                 Организация оффлайн и онлайн <br /> мероприятий
                                 при участии корейских <br /> компаний
                              </>
                           ) : (
                              <>
                                 Support and organization of <br /> business
                                 trips for Korean manufacturers
                                 <br /> of small and medium-sized businesses{" "}
                                 <br /> to Russia and to the countries <br /> of
                                 the Customs Union.
                                 <br />
                                 <br />
                                 Organization of offline <br /> and online
                                 events with the participation <br /> of Korean
                                 companies.
                              </>
                           )}
                        </p>
                 
                     </div>
                     <img
                        className={mainImg}
                        src="https://i.ibb.co/BwsWJ3W/business-acordion-img1-jpg-11.png "
                        alt="#"
                     />
                  </div>
                  <div className={cardHeader}>
                     <p className={number}>03</p>
                     <p className={headerTitle}>
                        {language === "RU"
                           ? "Организация   деловых поездок"
                           : "Organization of business trips"}
                     </p>
                     <img
                        className={arrow}
                        src="https://i.ibb.co/MRW3g8M/SVG.png"
                        alt="#"
                     />
                  </div>
               </div>
               <div
                  onMouseEnter={(e) => {
                     e.currentTarget.classList.add(cardActive);
                     e.currentTarget.parentElement.children[0].classList.remove(
                        cardActive
                     );
                  }}
                  onMouseLeave={(e) => {
                     e.currentTarget.classList.remove(cardActive);
                  }}
                  className={card}
               >
                  <div className={cardContent}>
                     <div className={cardText}>
                        <p className={cardTitle}>
                           {language === "RU" ? (
                              <>
                                 Дополнительные <br /> услуги
                              </>
                           ) : (
                              <>
                                 Additional <br /> services
                              </>
                           )}
                        </p>
                        <p className={subtitle}>
                           {language === "RU" ? (
                              <>
                                 {" "}
                                 Сертификация корейской продукции в <br />{" "}
                                 России, таможенное оформление, <br /> логистика
                                 и другие операции<br />
                                 Юридическое сопровождение по <br /> созданию
                                 местных дочерних <br />
                                 предприятий
                                 <br />
                                 <br />
                                 Обработка запросов от российских <br />{" "}
                                 компаний по поиску поставщиков <br /> продукции
                                 определенной категории.
                              </>
                           ) : (
                              <>
                                 Certification of Korean products in Russia,{" "}
                                 <br />
                                 customs clearance, logistics <br /> and other
                                 operations;
                                 <br />
                                 <br />
                                 Legal support for the opening <br /> of local
                                 subsidiaries;
                                 <br />
                                 <br />
                                 Processing requests from Russian <br />{" "}
                                 companies to find Korean suppliers <br /> of
                                 products of a certain category.
                              </>
                           )}
                        </p>
                      
                     </div>
                     <img
                        className={mainImg}
                        src="https://i.ibb.co/X42vGby/business-acordion-img1-jpg-12.png"
                        alt="#"
                     />
                  </div>
                  <div className={cardHeader}>
                     <p className={number}>04</p>
                     <p className={headerTitle}>
                        {" "}
                        {language === "RU" ? (
                           <>Юридическая поддержка</>
                        ) : (
                           <>Additional services</>
                        )}{" "}
                     </p>
                     <img
                        className={arrow}
                        src="https://i.ibb.co/MRW3g8M/SVG.png"
                        alt="#"
                     />
                  </div>
               </div>
            </div>
            <div className={mobileVersion}>
               <div
                  data-aos-duration="1000"
                  data-aos="fade-left"
                  className={mobileContainer}
               >
                  <div className={mobileContent}>
                     <div className={mobileImgContainer}>
                        <img
                           className={
                              id === 1
                                 ? `${mobileMainImg} ${mobileImgActive}`
                                 : mobileMainImg
                           }
                           src={
                              "https://i.ibb.co/zZCdL1G/202613da57830d5dcf2d89c8a2432989.jpg"
                           }
                           alt="#"
                        />
                        <img
                           className={
                              id === 2
                                 ? `${mobileMainImg} ${mobileImgActive}`
                                 : mobileMainImg
                           }
                           src={
                              "https://i.ibb.co/yWKLTRg/49468b6abfc185ed742232f811f31f5d.jpg"
                           }
                           alt="#"
                        />
                        <img
                           className={
                              id === 3
                                 ? `${mobileMainImg} ${mobileImgActive}`
                                 : mobileMainImg
                           }
                           src={
                              "https://i.ibb.co/BwsWJ3W/business-acordion-img1-jpg-11.png"
                           }
                           alt="#"
                        />
                        <img
                           className={
                              id === 4
                                 ? `${mobileMainImg} ${mobileImgActive}`
                                 : mobileMainImg
                           }
                           src={
                              "https://i.ibb.co/X42vGby/business-acordion-img1-jpg-12.png"
                           }
                           alt="#"
                        />
                     </div>

                     <div className={mobileTextContainer}>
                        <p className={cardTitleMobile}>
                           <span
                              className={id === 1 ? titleMobileActive : null}
                           >
                             {language === "RU" ? (
                              <>
                                 Информационная и <br /> коммуникационная <br />
                                 поддержка
                              </>
                           ) : (
                              <>
                                 Information and <br /> communication support
                              </>
                           )}
                           </span>
                           <span
                              className={id === 2 ? titleMobileActive : null}
                           >
                             {language === "RU" ? (
                              <>
                                 {" "}
                                 Организация <br /> переговоров
                              </>
                           ) : (
                              <>
                                 Organization <br />
                                 of negotiations
                              </>
                           )}
                           </span>
                           <span
                              className={id === 3 ? titleMobileActive : null}
                           >
                              {language === "RU" ? (
                              <>
                                 Организация <br /> деловых поездок
                              </>
                           ) : (
                              <>
                                 Organization of <br /> business trips
                              </>
                           )}
                           </span>
                           <span
                              className={id === 4 ? titleMobileActive : null}
                           >
                            {language === "RU" ? (
                              <>
                                 Дополнительные <br /> услуги
                              </>
                           ) : (
                              <>
                                 Additional <br /> services
                              </>
                           )}
                           </span>
                        </p>
                        <p className={subtitleMobile}>
                           <span className={id === 1 ? textMobileActive : null}>

                           {language === "RU" ? (
                              <>
                                 Организация деловой переписки и <br /> прямых
                              переговоров с участием <br /> российских компаний
                              и корейских <br /> поставщиков
                              <br className={br} />
                              <br className={br} />
                              Поддержка в продвижении корейской <br /> продукции
                              на начальном этапе: <br /> маркетинговые
                              исследования рынка, <br />
                              поиск потенциальных покупателей, PR- <br />{" "}
                              агентств; сопровождение экспортных <br />{" "}
                              контрактов
                              <br className={br} />
                              <br className={br} />
                              Помощь в адаптации промо-материалов <br /> на
                              русский язык
                              </>
                           ) : (
                              <>
                                 Organization of business correspondence <br />{" "}
                                 and direct negotiations between <br /> Russian
                                 companies and Korean suppliers; <br  className={br} /> <br className={br}  />
                                 Support in promoting Korean products at the
                                 <br />
                                 initial stage: market research,
                                 <br /> search for potential buyers <br /> and
                                 PR agencies; support <br /> of export contracts
                                 Assistance in <br /> adapting promotional
                                 materials into Russian.
                              </>
                           )}
                             
                           </span>
                           <span className={id === 2 ? textMobileActive : null}>

                           {language === "RU" ? (
                              <>
                                     Организация онлайн/оффлайн переговоров <br />{" "}
                              между потенциальными партнерами с <br />{" "}
                              предоставлением услуг переводчика{" "}
                              <br className={br} /> <br className={br} />
                              Организация торговых миссий на <br /> территории
                              России и в странах <br /> Таможенного союза
                              </>
                           ) : (
                              <>
                                 Organization of online/offline negotiations{" "}
                                 <br />
                                 between potential partners with <br />{" "}
                                 translation support provided
                                 <br className={br} />
                                 <br  className={br}  />
                                 Organization of trade missions <br /> on the
                                 territory of Russia <br /> and in the countries
                                 of the Customs Union
                              </>
                           )}
                         
                           </span>
                           <span className={id === 3 ? textMobileActive : null}>
                           {language === "RU" ? (
                              <>
                                  Сопровождение и организация деловых <br /> поездок
                              корейских производителей <br /> малого и среднего
                              бизнеса на территории <br /> России и в странах
                              Таможенного союза.
                              <br className={br} />
                              <br className={br} />
                              Организация оффлайн и онлайн <br /> мероприятий
                              при участии корейских <br /> компаний
                              </>
                           ) : (
                              <>
                                 Support and organization of <br /> business
                                 trips for Korean manufacturers
                                 <br  className={br} /> of small and medium-sized businesses{" "}
                                 <br  className={br}/> to Russia and to the countries <br /> of
                                 the Customs Union.
                                 <br className={br} />
                                 <br className={br} />
                                 Organization of offline <br /> and online
                                 events with the participation <br /> of Korean
                                 companies.
                              </>
                           )}
                            
                           </span>
                           <span className={id === 4 ? textMobileActive : null}>
                           {language === "RU" ? (
                              <>
                                Сертификация корейской продукции в <br /> России,
                              таможенное оформление, <br /> логистика и другие
                              операции <br />
                              Юридическое сопровождение по <br /> созданию
                              местных дочерних <br />
                              предприятий
                              <br className={br} />
                              <br className={br} />
                              Обработка запросов от российских <br /> компаний
                              по поиску поставщиков <br /> продукции
                              определенной категории.
                              </>
                           ) : (
                              <>
                                 Certification of Korean products in Russia,{" "}
                                 <br />
                                 customs clearance, logistics <br /> and other
                                 operations;
                                 <br className={br} />
                                 <br className={br} />
                                 Legal support for the opening <br /> of local
                                 subsidiaries;
                                 <br className={br} />
                                 <br className={br} />
                                 Processing requests from Russian <br />{" "}
                                 companies to find Korean suppliers <br /> of
                                 products of a certain category.
                              </>
                           )}
                             
                           </span>
                        </p>
                       
                     </div>
                  </div>
                  <div className={mobileNumberContainer}>
                     <p
                        onClick={() => setId(4)}
                        className={
                           id === 4
                              ? `${numberMobile} ${numberMobileActive}`
                              : numberMobile
                        }
                     >
                        04
                        <img
                           className={arrowMobile}
                           src="https://i.ibb.co/MRW3g8M/SVG.png"
                           alt="#"
                        />
                     </p>
                     <p
                        onClick={() => setId(3)}
                        className={
                           id === 3
                              ? `${numberMobile} ${numberMobileActive}`
                              : numberMobile
                        }
                     >
                        03
                        <img
                           className={arrowMobile}
                           src="https://i.ibb.co/MRW3g8M/SVG.png"
                           alt="#"
                        />
                     </p>
                     <p
                        onClick={() => setId(2)}
                        className={
                           id === 2
                              ? `${numberMobile} ${numberMobileActive}`
                              : numberMobile
                        }
                     >
                        02
                        <img
                           className={arrowMobile}
                           src="https://i.ibb.co/MRW3g8M/SVG.png"
                           alt="#"
                        />
                     </p>
                     <p
                        onClick={() => setId(1)}
                        className={
                           id === 1
                              ? `${numberMobile} ${numberMobileActive}`
                              : numberMobile
                        }
                     >
                        01
                        <img
                           className={arrowMobile}
                           src="https://i.ibb.co/MRW3g8M/SVG.png"
                           alt="#"
                        />
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ActivitySection;
