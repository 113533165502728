import React, { useState } from "react";
import styles from "./Header.module.css";
import { Link as ScrollLink } from "react-scroll";
import { Link as NavLink, useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar.jsx";
import { animateScroll as scroll } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { useLanguage } from "../../contexts/languageContext.js";
import { useModal } from "../../contexts/modalWindowContext.js";
import AOS from "aos";
const Header = ({ isWhiteHeader, isAbsolute, anotherPage }) => {
   const {
      header,
      logoContainer,
      headerContainer,
      headerMenu,
      btn,
      burgerMenu,
      changeLang,
      earth,
      arrow,
      mobileMenuContainer,
      ulList,
      ulListActive,
      headerAbsolute,
   } = styles;
   const [isOpen, setIsOpen] = useState(false);
   const { setModal } = useModal();
   const navigate = useNavigate();
   const { language, setLanguage } = useLanguage();
   return (
      <>
         <SideBar
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            anotherPage={anotherPage}
         />
         <header
            style={{ color: isWhiteHeader ? "white" : "black" }}
            className={isAbsolute ? `${header} ${headerAbsolute}` : header}
         >
            <div
               style={{
                  borderBottom: isWhiteHeader
                     ? "1px solid white"
                     : "1px solid #379bd5",
                  borderTop: isWhiteHeader
                     ? "1px solid white"
                     : "1px solid #379bd5",
               }}
               className={`container ${headerContainer}`}
            >
               <div onClick={() => navigate("/")} className={logoContainer}>
                  {isWhiteHeader ? (
                     <img src="https://i.ibb.co/kypHK6T/3.png" alt="GBC" />
                  ) : (
                     <img src="https://i.ibb.co/jyJn7SW/image.png" alt="GBC" />
                  )}
                  <p>Moscow</p>
               </div>
               <div className={mobileMenuContainer}>
                  <div
                     onClick={(e) => {
                        e.currentTarget.children[2].classList.toggle(
                           ulListActive
                        );
                     }}
                     className={changeLang}
                  >
                     <img
                        src="https://i.ibb.co/nM8Nrmb/rs-group-wrap-rs-group-rs-layer-wrap-rs-layer-business-rev-globe-png-1.png"
                        alt="#"
                        className={earth}
                     />
                     {isWhiteHeader ? (
                        <img
                           src="https://i.ibb.co/S3SC5qT/Vector-9.png"
                           alt="#"
                           className={arrow}
                        />
                     ) : (
                        <img
                           src="https://i.ibb.co/jbq7Q2j/Vector-8.png"
                           alt="#"
                           className={arrow}
                        />
                     )}

                     <ul
                        style={{ color: isWhiteHeader ? "white" : "black" }}
                        className={ulList}
                        onClick={(e) => {
                           setLanguage(e.target.dataset.lang);
                        }}
                     >
                        <li data-lang="RU">RU</li>
                        <li data-lang="EN">EN</li>
                     </ul>
                  </div>
                  {isWhiteHeader ? (
                     <img
                        onClick={() => setIsOpen(true)}
                        src="https://i.ibb.co/j656Br0/2.png"
                        alt="Menu"
                        className={burgerMenu}
                     />
                  ) : (
                     <img
                        onClick={() => setIsOpen(true)}
                        src="https://i.ibb.co/19N0LGG/Frame-2.png"
                        alt="Menu"
                        className={burgerMenu}
                     />
                  )}
               </div>

               <nav className={headerMenu}>
                  {anotherPage ? (
                     <HashLink
                        style={{ color: isWhiteHeader ? "white" : "black" }}
                        to="/#activity"
                 
                     >
                        {language === "RU" ? "Деятельность" : "What we do"}
                     </HashLink>
                  ) : (
                     <ScrollLink
                        style={{ color: isWhiteHeader ? "white" : "black" }}
                        to="activity"
                        smooth={true}
                     >
                        {language === "RU" ? "Деятельность" : "What we do"}
                     </ScrollLink>
                  )}
              
                  <ScrollLink
                     style={{ color: isWhiteHeader ? "white" : "black" }}
                     to={"partners"}
                     onClick={() => {
                        if(anotherPage) {
                           navigate("/")
                           setTimeout(() => {
                              scroll.scrollToBottom()
   
                           } , 100) 
                        }
                       
                     }}
                     smooth={true}
                  >
                     {language === "RU" ? "Наши партнеры" : "Our partners"}
                  </ScrollLink>
             

                  <NavLink
                     style={{ color: isWhiteHeader ? "white" : "black" }}
                     to={"/aboutUs"}
                  >
                     {language === "RU" ? "О нас" : "About us"}
                  </NavLink>
                  <NavLink
                     to={"/contacts"}
                     style={{ color: isWhiteHeader ? "white" : "black" }}
                  >
                     {language === "RU" ? "Контакты" : "Contacts"}
                  </NavLink>
               </nav>
               <button onClick={() => setModal(true)} className={btn}>
                  {language === "RU" ? "Связаться" : "Contact me"}

                  <img src="https://i.ibb.co/9cwWPQ6/Group-1.png" alt="#" />
               </button>
            </div>
         </header>
      </>
   );
};

export default Header;
