import React, { useState } from "react";
import styles from "./PartnersSection.module.css";
import Marquee from "react-fast-marquee";
import { useLanguage } from "../../contexts/languageContext";
import { useLocation } from "react-router-dom";
const PartnersSection = () => {
   const {
      section,
      title,
      korea,
      img,
      marquee,
      russiaContainer,
      marqueeRussia,
      marqueeRussiaActive,
      russiaBlock,
      imgRussia,
      activeArrow,
   } = styles;
   const [isActive, setIsActive] = useState(false);
   const {language} = useLanguage()
   const params = useLocation()
   return (
      <div id="partners"  className={section}>
         <p        data-aos-duration={!params.hash ? '1000' : null}
                  data-aos={!params.hash ? 'fade-right' : null} className={title}>
           
            {
               language === "RU" ?  ' Партнеры'  : 'Partners'
            }
         </p>
         <p
    data-aos-duration={!params.hash ? '1000' : null}
    data-aos={!params.hash ? 'fade-right' : null}
            className={`${title} ${korea}`}
         >
            {
               language === "RU" ? 'Корея' : 'Korea'
            }
            
         </p>

         <Marquee className={marquee}>
            <img
               className={img}
               src="https://i.ibb.co/jVB2KMy/10.png"
               alt="#"
            />
            <img
               className={img}
               src="https://i.ibb.co/0qYmZqk/11.png"
               alt="#"
            />

            <img
               className={img}
               src="https://i.ibb.co/gVYWMmS/12.png"
               alt="#"
            />

            <img
               className={img}
               src="https://i.ibb.co/VxyVvyt/14.png"
               alt="#"
            />

            <img
               className={img}
               src="https://i.ibb.co/8XXfCNH/15.png"
               alt="#"
            />

            <img
               className={img}
               src="https://i.ibb.co/n8ZGBrB/13.png"
               alt="#"
            />

            <img
               className={img}
               src="https://i.ibb.co/gTWRJ9T/16.png"
               alt="#"
            />
         </Marquee>

         <div className={russiaBlock}>
            <div
            data-aos-duration={!params.hash ? '1000' : null}
            data-aos={!params.hash ? 'fade-right' : null}
               className={russiaContainer}
            >
               <p className={title}>  {
               language === "RU" ? 'Россия' : 'Russia'
            }
            </p>
               <img
                  className={isActive ? activeArrow : null}
                  onClick={() => setIsActive(!isActive)}
                  src="https://i.ibb.co/z4HVjhs/Vector-7.png"
                  alt="#"
               />
            </div>
            <Marquee
               className={
                  isActive
                     ? `${marqueeRussia} ${marqueeRussiaActive}`
                     : marqueeRussia
               }
            >
               <img
                  className={imgRussia}
                  src="https://i.ibb.co/yNrnNz0/7-1.png"
                  alt="#"
               />
               <img
                  className={imgRussia}
                  src="https://i.ibb.co/z89jmNf/6-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/zJSWWXZ/5-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/zST0wpd/4-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/7JJ23VF/3-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/XW7HBkQ/2-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/ZcB278t/1-1.png"
                  alt="#"
               />
                   <img
                  className={imgRussia}
                  src="https://i.ibb.co/yNrnNz0/7-1.png"
                  alt="#"
               />
               <img
                  className={imgRussia}
                  src="https://i.ibb.co/z89jmNf/6-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/zJSWWXZ/5-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/zST0wpd/4-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/7JJ23VF/3-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/XW7HBkQ/2-1.png"
                  alt="#"
               />

               <img
                  className={imgRussia}
                  src="https://i.ibb.co/ZcB278t/1-1.png"
                  alt="#"
               />
            </Marquee>
         </div>
      </div>
   );
};

export default PartnersSection;
