import React from "react";
import styles from "./ModalWindow.module.css";
import { useLanguage } from "../../contexts/languageContext";
import { useState } from "react";
import { useRef } from "react";

import emailjs from '@emailjs/browser';
const ModalWindow = ({ isOpen, setIsOpen }) => {
   const { modal, cover, closeBtn, title, content, input, btn  , coverActive , modalActive} = styles;
   const { language } = useLanguage();
   const [value , setValue] = useState('')
   const formRef = useRef();
   const sendPhone = (e) => {
      e.preventDefault();
      if(value && value !== ' ') {
         emailjs.sendForm('service_cjiomus', 'template_oyehp7q', formRef.current , 'LrrV5-VP9r8gzgz_T')
         .then((result) => {
             console.log(result.text);
             setIsOpen(false)
             alert(language === 'RU' ?  'Спасибо за заявку' : 'Thanks for your application')
   
         }, (error) => {
             console.log(error.text);
             alert(language === 'RU' ?  'Не получилось отправить заявку' : 'Failed to submit application')
   
         });
      setValue('')

      }
    };
   return (
      <div className={isOpen ? `${cover} ${coverActive}` : cover}>
         <div className={isOpen ? `${modal} ${modalActive}` : modal}>
            <img
            onClick={() => setIsOpen(false)}
               className={closeBtn}
               src="https://i.ibb.co/tQXgsSg/image.png"
               alt="#"
            />
            <div className={content}>
               <p className={title}>
                  {language === "RU"
                     ? "Оставьте номер телефона"
                     : "Leave your phone number"}
               </p>
               <form ref={formRef} onSubmit={sendPhone}>
                  <input name="message" id="message" value={value} onChange={(e) => setValue(e.target.value)}  className={input} placeholder={"+7(018)085-86-07"} />

                  <button type="submit" className={btn}>
                     {language === "RU" ? "Отправить" : "Send"}
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
};
export default ModalWindow;
